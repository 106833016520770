.App {
  text-align: center;
}

html,
body {
  font-family: "Montserrat", "Cairo", sans-serif !important;
  font-weight: 500 !important ;
  padding: 5px;
  max-height: 100vh;
}
